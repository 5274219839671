import React from 'react'
import { Container, Row } from 'reactstrap'
import { StaticQuery, graphql } from "gatsby"

import PageTemplate from '../../components/pageTemplate'
import Service from '../../components/service'

const header = {
    title: '受託開発',
    subTitle: ''
}

const items = [
    {
        title: '目的に沿った適切な要件定義・開発',
        content: '作りたいシステムではなく目的を達成するためのシステムを開発し、将来の改修にも柔軟に対応する設計'
    },
    {
        title: '様々なプラットフォームに対応',
        content: 'Web・Android・iOS・Windows・macOSに対応'
    },
    {
        title: '保守・運用までのサポート',
        content: 'ご要望があればサーバー運用まで一貫サポート'
    }
]

let EntrustedDevelopment = () => (
    <PageTemplate title="サービス">
        <Container className="py-5">
            <Row>
                <EntrustedDevelopmentService header={header} items={items} />
                <p>
                    お客様の目的に応じて適切に設計を行い、将来の改修も考慮した開発を行います。<br />
                    様々なプラットフォームに対応し、Web開発ではサーバーの保守・運用まで一貫サポートも可能です。
                </p>
            </Row>
        </Container>
    </PageTemplate>
)

export default EntrustedDevelopment

const EntrustedDevelopmentService = (props) => (
    <StaticQuery
        query={graphql`
      query EntrustedDevelopmentService {
        file(relativePath: {eq: "operation.png"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
        render={data => (
            <Service data={data} {...props} />
        )}
    />
)
